

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../config";
import AgentContact from "../../components/super-admin/agent/AgentContact.vue";
import Axios from "axios";
import { authHeader } from "../../services/auth";
import { LOAN_TYPE,
APPLICABLE_FOR_TASK
} from "../../constants/constants";

//import { AgentInfo } from "../../models/agent/agent.model";
import CommonDeleteModal from "../../common-components/CommonDeleteModal.vue";
import {
  AgentInfo,
  MortgageClause,
  AppraisalCompany,
} from "../../models/agent/agent.model";

@Component({
  components: {
    AgentContact,
    CommonDeleteModal,
  },
})
export default class Agent extends Vue {
  public agentList = [];
  public taskList = [];
  public lenderId: any;
  public defaultSubmit = "Add";
  public isEdit = false;
  // public landerId: any = "";
  public agentInfo: any = new AgentInfo();
  public mortgageClause: any = new MortgageClause();
  public appraisalcompany: any = new AppraisalCompany();
  public loanType = LOAN_TYPE;
  public agentName: any = "";
  public stateAndCounty = [];
  public titleTask = false;
  public hazardTask = false;
  public floodTask = false;
  // public error = false;
  public applicableForTask = APPLICABLE_FOR_TASK;

  public mortgageClaseList: any = [];

  public getLoanType(clause){
    const data =  this.loanType.find(e=> e.value === clause.loanType)
    if (data)
    return data.name
  }

  public async getMortgageClause() {
    try {
      const response = await Axios.get(
        `${BASE_API_URL}agent/getMortgageClause/${this.$route.query._id}`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.mortgageClaseList = response.data.mortgageClause;
        this.agentName = response.data.agentName;
        // this.error = false;
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public editMortgageClause(data, lenderId) {
    this.isEdit = true;
    // this.error = false;
    // this.defaultSubmit = "Edit";
    this.lenderId = lenderId ? lenderId : this.$route.query._id;
    this.agentInfo.mortgageClause[0] = JSON.parse(JSON.stringify(data));
    this.$modal.show("addMortgageClause");
  }

  public async getStatesAndCounty() {
    try {
      const response = await Axios.get(
        BASE_API_URL + "common/getStatesAndCounty"
      );
      this.stateAndCounty = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  // public checkAlradyClause() {
  //   this.mortgageClaseList.mortgageClause;
  //   this.mortgageClaseList.mortgageClause.forEach((e: any) => {
  //     this.agentInfo.mortgageClause.forEach((b: any) => {
  //       if (e.applicableForTask == b.applicableForTask) this.error = true;
  //     });
  //   });
  // }
  public async getTaskList() {
    try {
      const response = await Axios.get(BASE_API_URL + "agent/getTaskList", {
        headers: authHeader(),
      });
      this.taskList = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public getData(applicableForTask) {
    this.taskList.forEach((e: any) => {
      if (e.id == applicableForTask) {
        applicableForTask = e.name;
      }
    });

    return applicableForTask;
  }

  /**********************************************************************************
   *               Open modal for Add Mortgage Clause                               *
   **********************************************************************************/
  public openMortgageClauseModal(id) {
    this.lenderId = id;
    this.$modal.show("addMortgageClause");
    this.agentInfo = new AgentInfo();
  }

  /**********************************************************************************
   *                Function for add More clause                                    *                                         *
   **********************************************************************************/
  public addMoreClause() {
    const data = new AgentInfo();
    this.agentInfo.mortgageClause.push({ ...data.mortgageClause[0] });
  }
  /*********************************************************************************
   *         Remove mortgage clause                                                *
   *********************************************************************************/
  public remove(index) {
    this.agentInfo.mortgageClause.splice(index, 1);
  }

  cancel() {
    this.$modal.hide("addLender");
    this.$modal.hide("addMortgageClause");
    this.lenderId = null;
    // this.error = false;
    // this.getMortgageClause();
  }

  /***********************************************************************************
   *          Saving Mortgage clause of specific lender                              *
   ***********************************************************************************/
  public async saveMortgageClauseOfLender() {
    try {
      // this.checkAlradyClause();
      if (this.isEdit)
        // this.error = false;
        this.defaultSubmit = "Edit";
      // else if (this.error) {
      //   return;
      // }
      else this.defaultSubmit = "Add";

      const data = {
        lenderId: this.lenderId ? this.lenderId : this.$route.query._id,
        mortgageClause: this.agentInfo.mortgageClause,
        defaultSubmit: this.defaultSubmit,
      };

      const response = await Axios.post(
        BASE_API_URL + "agent/saveMortgageClauseOfLender",
        { data: data },
        {
          headers: authHeader(),
        }
      );
      if (response.data.msg == "success") {
        this.$modal.hide("addMortgageClause");
        this.getMortgageClause();
      }
    } catch (error) {
      console.log(error);
    }
  }

  /*********************************************************************************
   *               Deleting mortgage clause of lender                              *
   *********************************************************************************/
  public async deleteMortgageClause(lenderId, indexId) {
    try {
      await this.$refs.ref["delConfirmationBox"]({
        msg: `Are you sure want to delete this mortgage clause ?`,
        data: {
          lenderId: lenderId ? lenderId : this.$route.query._id,
          indexId: indexId,
        },
        apiPath: "agent/deleteMortgageClause",
      });
    } catch (error) {
      console.log(error);
    }
  }
  async mounted() {
    this.lenderId = this.$route.query._id;
    // this.error = false;
    if (this.$route.query._id) {
      const mortgageClaseList = await this.getMortgageClause();
      //this.templateHeading = !this.templateHeading;
    }
    await this.getTaskList();
    // await this.getAppraisalList();
    await this.getStatesAndCounty();
  }
}
