/* eslint-disable @typescript-eslint/no-explicit-any */

export default class Name {
  constructor() {
    this.firstName = null;
    this.middleName = null;
    this.lastName = null;
    this.suffix = null;
  }

  public firstName: any;

  public middleName: any;

  public lastName: any;

  public suffix: any;
}
