import Address from '../common/address.model'
import fullName from '../common/name.model'


export class MortgageClause {
    constructor() {
        this.name = null;
        this.loanType = null;
        this.applicableForTask = null;
        this.clause = null;
        this.address = new Address();

    }
    public name: any;
    public loanType: any;
    public applicableForTask: any;
    public clause: any;
    public address: Address;

}

export class AppraisalCompany {
    constructor() {
        this.id = null;
        // this.username = null;
        // this.password = null;
    }
    public id: any;
    // public username: any;
    // public password: any;

}
export class AgentInfo {
    constructor() {
        this.type = "";
        this.name = null;
        this.website = null;
        this.isActive = null;
        this.address = new Address();
        this.appraisalcompany = [new AppraisalCompany];
        this.mortgageClause = [new MortgageClause];
        this.note = null;
        this.NMLSId = null;
        this.licenseId = null;
        this.isNa = null;
        this.message = null;
    }
    public type: any;
    public name: any;
    public website: any;
    public isActive: any
    public address: Address;
    public appraisalcompany: Array<AppraisalCompany>;
    public mortgageClause: Array<MortgageClause>;
    public note: any;
    public NMLSId: any;
    public licenseId: any;
    public isNa: any;
    public message: any;

}

export class AgentContactInfo {
    constructor() {
        this.fullName = new fullName();
        this.email = "";
        this.phone = "";
        this.contactNMLSId = null;
        this.contactlicenseId = null;
        this.fax = null

    }
    public fullName: any;
    public email: string;
    public phone: string;
    public contactNMLSId: any;
    public contactlicenseId: any;
    public fax: any;

}





