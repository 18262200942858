




















































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";

@Component({
  components: {},
})
export default class CommonDeleteModal extends Vue {
  public message = null;

  public aPIPath = null;

  public currentData = null;

  public reason = null;
  public loanList = [];

  /**
   * Delete Confirmation
   */
  public async delConfirmationBox(body) {
    try {
      this.message = body.msg;
      this.aPIPath = body.apiPath;
      this.currentData = body.data;
      this.$modal.show("deleteConfirmationModal");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * UserChoice
   */
  public async userChoice(userChoice: string) {
    try {
      if (userChoice === "NO") {
        await this.$snotify.info("No changes");
        this.$modal.hide("deleteConfirmationModal");
      } else {
        const res = await Axios.post(
          BASE_API_URL + this.aPIPath,
          this.currentData,
          { headers: authHeader() }
        );
        if (res.status === 201) {
          if (res.data.error) {
            await this.$snotify.error(res.data.message);
          } else {
            await this.$snotify.success(res.data.message);
          }
          this.loanList = res.data.loanData;
          this.$modal.hide("deleteConfirmationModal");
          this.$emit("call-mount-funtion");
        } else {
          this.reason = res.data.message;
          this.loanList = res.data.loanData;
          // console.log(this.loanList,'loanList');
          this.$modal.hide("deleteConfirmationModal");
          this.$modal.show("reasonModal");
          this.$emit("call-mount-funtion");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
}
